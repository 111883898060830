import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { Provider } from 'react-redux'
import * as _redux from "./redux"
import store, { persistor } from "./redux/store"
import { ToastContainer } from 'react-toastify'
import axios from "axios";

 const { PUBLIC_URL } = process.env

 _redux.setupAxios(axios, store)
 
 const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
    <Provider store={store}>
      {/* <Suspense fallback={<Spinner />}> */}
      <Suspense fallback={null} >
          <LazyApp store={store} persistor={persistor} basename={PUBLIC_URL} />
          <ToastContainer newestOnTop />
      </Suspense>
    </Provider>,
  document.getElementById('root')
)

