import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import loginSlice from "../app/modules/Auth/_redux/Login/loginSlice";
import { articlesSlice } from "../app/modules/Blogs/redux/articles/Slice";
import { categoriesSlice } from "../app/modules/Blogs/redux/categories/Slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  login: loginSlice,
  articles: articlesSlice.reducer,
  articleCategories: categoriesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
