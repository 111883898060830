import axios from "axios"
import apiConfig from "../../../../configs/apiConfig"
import { baseURL } from "../../../../redux"
import {toastError, getTokenData} from '../../../../utils'

const AUTH_URL = `${baseURL}/UsersAuth`

 axios.interceptors.request.use((response) => {
   response.headers["Accept-Language"] = "tr";
   return response;
 });

axios.interceptors.response.use(config => {
  return config
}, async err => {
  const statusCode = err.response.status;

  if (statusCode === 401) {

    const { RefreshToken, RefreshTokenExpiration, AccessTokenExpiration } = getTokenData()

    const refreshTokenExp = new Date(RefreshTokenExpiration).getTime()
    const accessTokenExp = new Date(AccessTokenExpiration).getTime()
    const currentTime = new Date().getTime()

    if (refreshTokenExp > currentTime && currentTime > accessTokenExp) {
      axios.post(`${apiConfig.url}/UsersAuth/RefreshAccessToken`, {
        refreshToken: RefreshToken
      }).then(({ data }) => {
        const user = data.Data.User
        const userToken = data.Data.UserToken

        localStorage.setItem('userData', JSON.stringify(user))
        localStorage.setItem('tokenData', JSON.stringify(userToken))
        
      }).catch(e => {
        localStorage.removeItem('userData')
        localStorage.removeItem('tokenData')
        window.location.replace('/')
      })

    } else {
      localStorage.removeItem('userData')
      localStorage.removeItem('tokenData')
      window.location.replace('/')
    }


  }else if(statusCode === 400 ){
    err.response.data && err.response.data.ValidationError
      ? toastError(err.response.data.ValidationError[0].Message)
      : err.response.data && err.response.data.ValidationErrors
      ? toastError(err.response.data.ValidationErrors[0].Message)
      : err.response.data && err.response.data.Error && err.response.data.Error.Message
      ? toastError(err.response.data.Error.Message)
      : err.response.data && err.response.data.Error && err.response.data.Error[0]
      ? toastError(err.response.data.Error[0].Message)
      : console.log(`no errors`)

    // if(err.response.data.Error.PropertyName){
    //     setHasBeenLocked(true)
    // }
    
  }else if(statusCode === 404 ){
    toastError(err.response.data.Error.Message)
    
  }else if(statusCode === 500 ){
     toastError('Sunucu tarafında bir hata oluştu, lütfen bize bildirin.')    
  }
})

export function login(email, password,wrongLoginCount) {
  return axios.post(`${AUTH_URL}/Login`, {
    email,
    password,
  })
}

export function socialLogin(data) {
  return axios.post(`${AUTH_URL}/SocialLogin`, data)
}

export function forgotPassword(email, activationCode) {
  return axios.post(`${AUTH_URL}/ForgotPassword`, {
    email,
    activationCode
  })
}

export function changePassword(data, accessToken) {
  return axios.post(`${baseURL}/Users/ChangePassword`, data, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  })
}

export function reSendActivationCode(email) {
  return axios.post(`${AUTH_URL}/ReSendActivationCode?emailAddress=${email}`)
}

export function activeUserByActivationCode(code) {
  return axios.post(`${AUTH_URL}/ActivateUserByActivationCode`, code)
}