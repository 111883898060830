import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  error: null,
  success: false,
  totalCount: 0,
  userArticleForEdit: null,
  banks: [],
  isShowingQRCodeModal: false,
  showLinkStart: 0,
  showLinkEnd: 6,
  TotalPages: null,
  numberOfItemsToShow: 6,
  click: false
};

export const actionTypes = {
  list: "list",
  action: "action",
};

export const articlesSlice = createSlice({
  name: "articles",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      state.success = false;

      if (action.payload.callType === actionTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.success = false;
      if (action.payload.callType === actionTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    articleFetched: (state, action) => {
      state.actionsLoading = false;
      state.userArticleForEdit = action.payload;
      state.error = null;
    },
    articlesFetchedWithoutPaging: (state, action) => {
      state.actionsLoading = false;
      state.entities = action.payload;
      state.error = null;
    },
    articlesFetchedGetAll: (state, action) => {
      const { Articles, TotalPages  } = action.payload
      state.actionsLoading = false;
      state.entities = Articles;
      state.TotalPages = TotalPages;
      state.error = null;
    },
    clickAdded: (state, action) => {
      state.click = action.payload;
    },
    incrementPagination: (state, action) => {
      return {
        ...state,
        showLinkStart: state.showLinkStart + action.payload,
        showLinkEnd: state.showLinkEnd + action.payload,
      };
    },
    decrementPagination: (state, action) => {
      return {
        ...state,
        showLinkStart: state.showLinkStart - action.payload,
        showLinkEnd: state.showLinkEnd - action.payload,
      };
    },
    setNumberOfItemsToShow: (state, action) => {
      return {
        ...state,
        numberOfItemsToShow: parseInt(action.payload),
        showLinkEnd: parseInt(action.payload),
      };
    },
  },
});
