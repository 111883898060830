import { createSlice } from "@reduxjs/toolkit"
import { login } from '../authCrud'
import * as requestToServer from '../authCrud'
import { toastError, toastSuccess } from "../../../../../utils";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    user: null,
    authToken: null,
    refreshToken: null,
    expiredToken: null,
    account_has_been_locked: false,
  },
  reducers: {
    setUser: (state, action) => {
      const { UserDto, UserTokenDto } = action.payload;

      state.user = UserDto;
      state.authToken = {
        Token: UserTokenDto.AccessToken,
        TokenExpiration: UserTokenDto.TokenExpiration,
      };
      state.refreshToken = {
        Token: UserTokenDto.RefreshToken,
        TokenExpiration: UserTokenDto.RefreshTokenExpiration,
      };
    },
    setHasBeenLocked: (state, action) => {
      state.account_has_been_locked = action.payload;
    },
  },
});

export const { setUser, setHasBeenLocked } = loginSlice.actions
export default loginSlice.reducer

/**
 * Parameters for userLogin slice action
 * @param {String} email - User email address
 * @param {String} password - User Password
 * @param {String} culture - User language
 * @returns {Promise}
 */
export const userLogin = (email, password) => async dispatch => {
  try {
        const res = await login(email, password)
        
        if (res) {
          return res 
        } else {
          return 404;
        }
    
    } catch (err) {
        const response = err?.response?.data;
        toastError(response?.Error?.Message);
        if (response.ValidationErrors && response.ValidationErrors[0]) {
            for (let i = 0; i < response.ValidationErrors.length; i++) {
                toastError(response.ValidationErrors[i].Message);
            }
        } else {
        }

    }
}