// ** MakasDigi api config options
const apiConfig = {
  url: "https://api.tamly.co/api/v1",
  
  endpoints: {
    Locations: "/Locations",
    Links: "/Links",
    Requests: "/Requests",
    UserPictures: "/UserPictures",
    Users: "/Users",
    UsersAuth: "/UsersAuth",
    WorkspacePictures: "/WorkspacePictures",
    Workspaces: "/Workspaces",
    WorkspacePublicProfiles: "/WorkSpacePublicProfiles"
  }
}

export default apiConfig
